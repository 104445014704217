<template>
  <div class="card flex justify-center">
    <Toast :position="message.position || 'center'"/>
  </div>
</template>

<script setup lang="ts">
import { useToast } from "primevue/usetoast";
import Toast from 'primevue/toast';
import {ToastMessageInterface} from "@/components/common/interfaces/ToastMessageInterface";

export interface MessageComponentRef {
  show: () => void;
}

// eslint-disable-next-line no-undef
const props = defineProps<{ message: ToastMessageInterface }>();

const toast = useToast();

function show(){
  toast.add({
    severity: props.message.severity,
    summary: props.message.summary,
    detail: props.message.detail,
    life: props.message.life || 5000 });

}

defineExpose({
  show
});

</script>
