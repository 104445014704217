import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "px-6 py-20 md:px-12 lg:px-20 flex items-center justify-center bg-gray-300 dark:bg-black h-screen" }
const _hoisted_2 = { class: "p-12 shadow text-center lg:w-[30rem] backdrop-blur-md rounded-xl bg-white" }
const _hoisted_3 = { class: "p-field" }
const _hoisted_4 = {
  key: 0,
  class: "p-error mb-4 bg-amber-100 p-2"
}
const _hoisted_5 = { class: "p-field" }
const _hoisted_6 = {
  key: 0,
  class: "p-error mb-4 bg-amber-100 p-2"
}

import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import {computed, onMounted, ref} from "vue";
import useVuelidate from "@vuelidate/core";
import {email, minLength, required} from "@vuelidate/validators";
import SignInService from "@/views/signin/services/SignIn.service";
import MessageComponent from "@/components/common/MessageComponent.vue";
import type {ToastMessageInterface} from "@/components/common/interfaces/ToastMessageInterface";
import router from "@/router";
import { MessageComponentRef } from "@/components/common/MessageComponent.vue";
import { isAuthenticated} from "@/utils/Utils";


export default /*@__PURE__*/_defineComponent({
  __name: 'SignInView',
  setup(__props) {

const messageComponent = ref<MessageComponentRef | null>(null);
const txt_email = ref('')
const password = ref('')
const toastMessage = ref<ToastMessageInterface>({
  severity: 'info',
  summary: 'Informazione',
  detail: 'Questo è un messaggio di esempio',
  position: 'center',
});

onMounted(()=>{
  if (!isAuthenticated()) {
    router.push('/'); // Se non c'è l'utente, reindirizza alla login
  } else {
    router.push('/home');
  }
})

const rules =computed(() => (
    {
      txt_email: {
        required,
        email,
        minLength: minLength(5),
      },
      password:{
        required,
        minLength: minLength(4),
      }
    }
));

const $v = useVuelidate(rules, { txt_email, password });

const submitForm = () => {
  const result = $v.value.$validate();
  result.then(async (res) => {
    if (res) {
      try {
        const login = await SignInService.signIn(txt_email.value.trim(), password.value.trim());
        if (login?.data.status === 'error') {
          toastMessage.value.severity = 'info';
          toastMessage.value.detail = login?.data.data || 'Controlla le credenziali';
          showToast();
        } else if (login!.status <=299 ) {
          localStorage.setItem('user', JSON.stringify(login!.data.data));
          await router.push('/home');
        } else {
          toastMessage.value.severity = 'info';
          toastMessage.value.detail = 'Errore generico nella login';
          showToast();
        }

      } catch (e) {
        toastMessage.value.severity = 'error';
        toastMessage.value.detail = 'Errore API: ' + e;
        showToast();
      }
    }
  }).catch((err) => {
    toastMessage.value.severity = 'error';
    toastMessage.value.detail = 'Errore submit form: ' + err;
    showToast();
  })

};

const showToast = () => {
  if (messageComponent.value) {
    messageComponent.value.show();
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(MessageComponent, {
      ref_key: "messageComponent",
      ref: messageComponent,
      message: toastMessage.value
    }, null, 8, ["message"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "mb-4 font-bold" }, "Gardauno APPS login", -1)),
        _createElementVNode("form", {
          onSubmit: _withModifiers(submitForm, ["prevent"])
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(InputText), {
              id: "txt_email",
              name: "txt_email",
              modelValue: txt_email.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((txt_email).value = $event)),
              type: "text",
              class: "!appearance-none placeholder:!text-primary-contrast/40 !border-1 !p-4 !w-full !outline-0 !text-xl !block !mb-6 !bg-white/10 !text-primary-contrast/90 !rounded-full",
              placeholder: "Email"
            }, null, 8, ["modelValue"]),
            (_unref($v).txt_email.$error && !_unref($v).txt_email.$pending)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[2] || (_cache[2] = [
                  _createElementVNode("div", { class: "text-red-700" }, "Email obbligatoria.", -1),
                  _createElementVNode("div", { class: "text-red-700" }, "Deve essere una email valida.", -1),
                  _createElementVNode("div", { class: "text-red-700" }, "Almeno 5 caratteri.", -1)
                ])))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_unref(InputText), {
              id: "password",
              name: "password",
              modelValue: password.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
              type: "password",
              class: "!appearance-none placeholder:!text-primary-contrast/40 !border-1 !p-4 !w-full !outline-0 !text-xl !mb-6 !bg-white/10 !text-primary-contrast/90 !rounded-full",
              placeholder: "Password"
            }, null, 8, ["modelValue"]),
            (_unref($v).password.$error && !_unref($v).password.$pending)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[3] || (_cache[3] = [
                  _createElementVNode("div", { class: "text-red-700" }, "Password obbligatoria.", -1),
                  _createElementVNode("div", { class: "text-red-700" }, "Password deve essere almeno 4 caratteri.", -1)
                ])))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_unref(Button), {
            type: "submit",
            class: "max-w-40 w-full rounded-full appearance-none border-0 p-4 outline-0 text-xl mb-6 font-medium text-primary-contrast/80 cursor-pointer transition-colors duration-150"
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode(" Accedi ")
            ])),
            _: 1
          })
        ], 32)
      ])
    ])
  ], 64))
}
}

})