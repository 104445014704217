<template>
  <Toast position="center" group="bc" @close="onClose">
    <template #message="slotProps">
      <div class="flex flex-col items-start flex-auto">
        <div class="flex items-center">
          <span class="font-bold">{{ slotProps.message.summary }}</span>
        </div>
        <div class="font-medium text-lg my-4">{{ slotProps.message.detail }}</div>
        <Button size="small" label="Forza aggiornamento" severity="success" @click="onReply()"></Button>
      </div>
    </template>
  </Toast>
  <div class="bg-gray-300 dark:bg-black h-[100vh] w-[100vw]">
    <router-view/>
  </div>
</template>

<script setup language="ts">
import Toast from "primevue/toast";
import Button from "primevue/button";
import {useToast} from 'primevue/usetoast';
import {onMounted, ref} from 'vue';

const toast = useToast();
const visible = ref(false);

// Ascolta l'evento 'swUpdated' e mostra un toast quando viene emesso
onMounted(() => {
  document.addEventListener('swUpdated', () => {
    toast.add({
      severity: 'info',
      group: 'bc',
      summary: 'Aggiornamento disponibile',
      detail: 'L\'aggiornamento si applica in automatico fra pochi secondi, oppure forzalo tu cliccando sul bottone.',
      life: 5000
    });
    visible.value = true;
    // Esegui il refresh della pagina dopo 5 secondi
    setTimeout(() => {
      window.location.reload(true);  // Forza il reload della pagina
    }, 5000);  // Tempo di attesa in millisecondi prima del refresh
  });
});

const onReply = () => {
  toast.removeGroup('bc');
  visible.value = false;
  window.location.reload(true);  // Forza il reload della pagina
}

const onClose = () => {
  visible.value = false;
  window.location.reload(true);  // Forza il reload della pagina
}
</script>