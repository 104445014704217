<template>
  <MessageComponent ref="messageComponent" :message="toastMessage"/>
  <div
      class="px-6 py-20 md:px-12 lg:px-20 flex items-center justify-center
      bg-gray-300
      dark:bg-black
      h-screen
      "
  >

    <div class="p-12 shadow text-center lg:w-[30rem] backdrop-blur-md rounded-xl
    bg-white">
      <div class="mb-4 font-bold">Gardauno APPS login</div>
      <form @submit.prevent="submitForm">
        <div class="p-field">
          <InputText
              id="txt_email"
              name="txt_email"
              v-model="txt_email"
              type="text"
              class="!appearance-none placeholder:!text-primary-contrast/40 !border-1 !p-4 !w-full !outline-0 !text-xl !block !mb-6 !bg-white/10 !text-primary-contrast/90 !rounded-full"
              placeholder="Email"
          />
          <div v-if="$v.txt_email.$error && !$v.txt_email.$pending" class="p-error mb-4 bg-amber-100 p-2">
            <div class="text-red-700">Email obbligatoria.</div>
            <div class="text-red-700">Deve essere una email valida.</div>
            <div class="text-red-700">Almeno 5 caratteri.</div>
          </div>
        </div>
        <div class="p-field">
          <InputText
              id="password"
              name="password"
              v-model="password"
              type="password"
              class="!appearance-none placeholder:!text-primary-contrast/40 !border-1 !p-4 !w-full !outline-0 !text-xl !mb-6 !bg-white/10 !text-primary-contrast/90 !rounded-full"
              placeholder="Password"
          />
          <div v-if="$v.password.$error && !$v.password.$pending" class="p-error mb-4 bg-amber-100 p-2">
            <div class="text-red-700">Password obbligatoria.</div>
            <div class="text-red-700">Password deve essere almeno 4 caratteri.</div>
          </div>
        </div>
        <Button
            type="submit"
            class="max-w-40 w-full rounded-full appearance-none border-0 p-4 outline-0 text-xl mb-6 font-medium text-primary-contrast/80 cursor-pointer transition-colors duration-150"
        >
          Accedi
        </Button>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import {computed, onMounted, ref} from "vue";
import useVuelidate from "@vuelidate/core";
import {email, minLength, required} from "@vuelidate/validators";
import SignInService from "@/views/signin/services/SignIn.service";
import MessageComponent from "@/components/common/MessageComponent.vue";
import type {ToastMessageInterface} from "@/components/common/interfaces/ToastMessageInterface";
import router from "@/router";
import { MessageComponentRef } from "@/components/common/MessageComponent.vue";
import { isAuthenticated} from "@/utils/Utils";

const messageComponent = ref<MessageComponentRef | null>(null);
const txt_email = ref('')
const password = ref('')
const toastMessage = ref<ToastMessageInterface>({
  severity: 'info',
  summary: 'Informazione',
  detail: 'Questo è un messaggio di esempio',
  position: 'center',
});

onMounted(()=>{
  if (!isAuthenticated()) {
    router.push('/'); // Se non c'è l'utente, reindirizza alla login
  } else {
    router.push('/home');
  }
})

const rules =computed(() => (
    {
      txt_email: {
        required,
        email,
        minLength: minLength(5),
      },
      password:{
        required,
        minLength: minLength(4),
      }
    }
));

const $v = useVuelidate(rules, { txt_email, password });

const submitForm = () => {
  const result = $v.value.$validate();
  result.then(async (res) => {
    if (res) {
      try {
        const login = await SignInService.signIn(txt_email.value.trim(), password.value.trim());
        if (login?.data.status === 'error') {
          toastMessage.value.severity = 'info';
          toastMessage.value.detail = login?.data.data || 'Controlla le credenziali';
          showToast();
        } else if (login!.status <=299 ) {
          localStorage.setItem('user', JSON.stringify(login!.data.data));
          await router.push('/home');
        } else {
          toastMessage.value.severity = 'info';
          toastMessage.value.detail = 'Errore generico nella login';
          showToast();
        }

      } catch (e) {
        toastMessage.value.severity = 'error';
        toastMessage.value.detail = 'Errore API: ' + e;
        showToast();
      }
    }
  }).catch((err) => {
    toastMessage.value.severity = 'error';
    toastMessage.value.detail = 'Errore submit form: ' + err;
    showToast();
  })

};

const showToast = () => {
  if (messageComponent.value) {
    messageComponent.value.show();
  }
};
</script>

