import axios from "axios";


export default class SignInService {
    static async signIn(email: string, password: string) {
        const apiString=process.env.VUE_APP_API_USER+":"+process.env.VUE_APP_API_PASSWORD;
        try {
            return await axios.post(process.env.VUE_APP_BASE_API_URL + 'sinistri/login_check', {
                username: email,
                password: password
            }, {
                headers: {
                    'Content-Type':  'application/json',
                    Authorization: 'Basic ' + btoa(apiString)
                }
            });
        } catch (e) {
            console.log(e);
        }
    }
}