import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card flex justify-center" }

import { useToast } from "primevue/usetoast";
import Toast from 'primevue/toast';
import {ToastMessageInterface} from "@/components/common/interfaces/ToastMessageInterface";

export interface MessageComponentRef {
  show: () => void;
}

// eslint-disable-next-line no-undef

export default /*@__PURE__*/_defineComponent({
  __name: 'MessageComponent',
  props: {
    message: {}
  },
  setup(__props: any, { expose: __expose }) {

const props = __props;

const toast = useToast();

function show(){
  toast.add({
    severity: props.message.severity,
    summary: props.message.summary,
    detail: props.message.detail,
    life: props.message.life || 5000 });

}

__expose({
  show
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Toast), {
      position: _ctx.message.position || 'center'
    }, null, 8, ["position"])
  ]))
}
}

})